<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.searchType"
                       placeholder="请选择关键字类型"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class=""
                      placeholder="搜索关键字"
                      prefix-icon="el-icon-search"
                      v-model="searchData.searchValue"
                      @keyup.enter.native="search"
                      style="width: 260px">
            </el-input>
          </el-form-item>

          <el-form-item>
          <el-select v-model="searchData.isZeroStock"
                     placeholder="是否零库存"
                     style="width: 120px">
            <el-option
              v-for="item in isZeroStock"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" round @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </template>

      <template v-slot:table="row">
        <el-table :data="tableData" :height="row.heightText" v-if="row.height">
          <el-table-column
            prop="goodsId"
            label="商品编码"
            width="200">
          </el-table-column>
          <el-table-column
            prop="goodsName"
            label="商品名称"
            width="250">
          </el-table-column>
          <el-table-column
            label="商品类型"
            width="100">
            <template slot-scope="scope">
              {{businessTypearr[scope.row.businessType]}}
            </template>
          </el-table-column>
          <el-table-column
            label="图片"
            width="100">
            <template slot-scope="scope">
              <img class="goods-cover" :src="scope.row.cover">
            </template>
          </el-table-column>
          <el-table-column
            prop="skuId"
            label="sku编码"
            width="200">
          </el-table-column>
          <el-table-column
            prop="skuName"
            label="sku规格"
            width="200">
          </el-table-column>
          <el-table-column
            prop="price"
            label="售价"
            width="100">
          </el-table-column>
          <el-table-column
            prop="purchasePrice"
            label="供货价"
            width="100"
            v-if="showPoint">
          </el-table-column>
          <el-table-column
            prop="stockNum"
            label="库存"
            width="80">
            <template slot-scope="scope">
              <div :style="scope.row.stockNum <= 10?'color:red':''">{{scope.row.stockNum}}</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="small" icon="el-icon-edit" @click="editStock(scope.row)">修改库存</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>

  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {disposeSearchData} from '../../../assets/js/utils'
  import {mapState} from 'vuex'

  export default {
    name: 'supplierStockList',
    components: {

    },
    data(){
      return {
        loading: false,
        /* 数据表格 */
        searchKeyType:[
          {id: 'goodsId', name: '商品编码'},
          {id: 'goodsName', name: '商品名称'},
          // {id: 'brandName', name: '品牌名称'},
          {id: 'skuId', name: 'sku编码'},
        ],
        isZeroStock:[
          {id:'1', name:"是"},
          {id:'2', name:"否"},
        ],
        searchData:{
          searchType:'goodsName',
          searchValue:'',
          isZeroStock:''
        },
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 10, // 每页显示得条数
        /* 新增属性组弹窗 */
        visibleAddNew:false,
        editGoodsData:{},
        businessTypearr:['','零售商品','包装','运营商品','产品拍图']
      }
    },
    created(){
      this.getList()
    },
    methods:{
      /**
       * 拼接搜索参数
       */
      jointSearchData(type){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data)
        return data;
      },
      /**
       * 搜索
       */
      search(type){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierGoods.stockList,{params:data}).then(res=>{
            this.totalListNum = Number(res.data.total);
            this.tableData = res.data.records;
            console.log(this.tableData)
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
      editStock(row) {
        this.$prompt('请输入库存数量', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]\d*/,
          inputErrorMessage: '请输入正整数库存数量',
          inputValue:row.stockNum,
          closeOnClickModal:false,
        }).then(({ value }) => {
          let data = {
            skuId:row.skuId,
            stockNum: value
          }

          console.log("您输入的库存是："+value);
          this.loading = true;
          this.axios.post(URL.supplierGoods.editStock,this.qs.stringify(data)).then(res=>{
            this.getList()
          }).catch(res=>{
            // return Promise.reject(res);
          }).finally(res=>{
            this.loading = false;
          });
        }).catch(() => {
          this.loading = false;
        });
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val,type){
        this.currentPage = val;
        this.getList();
      },
    },
    computed:{
      ...mapState({
        showPoint:state=>state.userInfo.showCostAndPurchase,
      }),
    },
  }
</script>

<style lang="less">
  .form-item{
    .el-input{
      max-width: 300px;
    }
  }
</style>
<style lang="less" scoped>
  .goods-cover{
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
</style>
